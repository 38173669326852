
import { Vue, Component, Prop } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import Profile from "@/types/profile";

@Component
export default class ProfilesTable extends Vue {
  @Prop({ default: [] }) readonly profiles!: Array<Profile>;

  headers: Array<DataTableHeader>;
  search: string;

  constructor() {
    super();
    this.headers = [
      { text: "Nome", value: "name", sortable: true },
      { text: "Empresa", value: "companyName", sortable: true },
      // { text: "Permissões", value: "permissions", sortable: false },
      { text: "Ações", value: "actions", sortable: false }
    ];
    this.search = "";
  }

  emitAction(item: Profile, operation: { manage?: boolean; delete?: boolean }) {
    item.index =
      this.profiles.indexOf(item) > -1 ? this.profiles.indexOf(item) : null;
    this.$emit("action", {
      item,
      ...operation
    });
  }
}
