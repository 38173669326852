
import { Vue, Component } from "vue-property-decorator";
import Page from "@/components/core/dashboard/Page.vue";
import ProfileManageModal from "@/components/profiles-permissions/ProfileManageModal.vue";
import ProfilesTable from "@/components/profiles-permissions/ProfilesTable.vue";
import Profile from "@/types/profile";
import ManageEvent from "@/types/manage-event";
import SafetyService from "@/services/safety-service";
import CompanyService from "@/services/company-service";
import clone from "just-clone";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import PermissionAccess from "@/types/permission";

type CheckablePermission = PermissionAccess & { checked: boolean };

type ProfileWithCheckablePermissions = Omit<Profile, "permissions"> & {
  permissions: Array<CheckablePermission>;
};

@Component({
  components: {
    Page,
    ProfileManageModal,
    ProfilesTable
  }
})
export default class ProfilesPermissions extends Vue {
  showManageModal: boolean;

  // Profile-related objects
  defaultItem: ProfileWithCheckablePermissions = {
    id: 0,
    companyId: null,
    name: "",
    permissions: [],
    index: null
  };
  itemProfile: ProfileWithCheckablePermissions;
  profilesData: Array<Profile>;
  availablePermissions: Array<CheckablePermission>;
  companies: Array<{ id: number; name: string }> | null = null;

  safetyService: SafetyService;
  companyService: CompanyService;

  constructor() {
    super();

    this.safetyService = SafetyService.getInstance();
    this.companyService = CompanyService.getInstance();
    this.showManageModal = false;
    this.availablePermissions = [];
    this.itemProfile = {
      ...this.defaultItem,
      permissions: clone(this.availablePermissions).map((p) => ({
        ...p,
        checked: true
      }))
    };
    this.profilesData = [];
  }

  handleAction($event: ManageEvent) {
    const permissionsFullList = this.availablePermissions.map((p) => {
      const permissionInGroup = $event?.item.permissions.includes(p.name);
      return {
        ...p,
        checked: permissionInGroup
      };
    });

    this.itemProfile = {
      ...$event?.item,
      permissions: permissionsFullList
    };

    if ("manage" in $event) {
      this.showManageModal = $event.manage!;
    }
  }

  close() {
    this.showManageModal = false;

    this.itemProfile = {
      ...this.defaultItem,
      permissions: clone(this.availablePermissions)
    };
  }

  async handleSaveProfile() {
    const { id, name, permissions, index, companyId } = this.itemProfile;

    const permissionNames = permissions
      .filter((p) => p.checked)
      .map((p) => p.name);

    let error, profile;

    if (index === null) {
      // Create new Profile
      [error, profile] = await this.safetyService.createProfile({
        name,
        permissions: permissionNames,
        companyId: companyId!
      });
    } else {
      // Update existing Profile
      [error, profile] = await this.safetyService.updateProfile(id, {
        name,
        permissions: permissionNames
      });
    }

    if (!error) {
      this.$notify({ type: "success", text: "Perfil salvo com sucesso." });
      await this.fetchProfiles(); // Reload so that the list is updated
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
  }

  async fetchProfiles() {
    const [error, profilesData] = await this.safetyService.listProfiles();

    if (!error) {
      this.profilesData = profilesData.map((profile: any) => {
        const customProfile = { ...profile };
        const company = this.companies?.find(
          (company) => company.id === profile.companyId
        );
        customProfile.companyName = company?.name;
        return customProfile;
      });
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
  }

  async fetchPermissions() {
    const [error, permissionsData] = await this.safetyService.listPermissions(
      "EMPLOYEE"
    );

    if (!error) {
      const mapPermissionsFunction = (p: PermissionAccess) => {
        const requiredForTypes = JSON.parse(p.requiredForTypesJson) as string[];
        return {
          ...p,
          checked: true,
          disabled: requiredForTypes.includes("EMPLOYEE")
        };
      };

      const permissions = permissionsData!.reduce(
        (accumulated, current) => accumulated.concat(current.permissions),
        [] as PermissionAccess[]
      );

      this.availablePermissions = permissions.map(mapPermissionsFunction);
      this.itemProfile = {
        ...this.defaultItem,
        permissions: clone(this.availablePermissions)
      };
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
  }

  async fetchCompanies() {
    const [companyError, companies] = await this.companyService.listCompanies({
      page: 1,
      limit: 100000000
    });

    if (companyError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de empresas para o filtro."
      });
    }

    this.companies = companies!.items;
  }

  async created() {
    await this.fetchCompanies();
    await this.fetchProfiles();
    await this.fetchPermissions();
  }
}
