
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Profile from "@/types/profile";
import Modal from "@/components/Modal.vue";

@Component({
  components: { ValidationObserver, Modal },
})
export default class ProfileManageModal extends Vue {
  @Prop() show!: boolean;
  @Prop() readonly item!: Profile;
  @Prop() readonly companies!: Array<{ id: number; name: string }> | null;

  constructor() {
    super();
  }

  get formTitle() {
    return this.item.index === null ? "Novo perfil" : "Editar perfil";
  }
}
